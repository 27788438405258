const local = 2// 1生产环境  2测试环境
const signLogin = {
  'prod': '', // 生产环境 勿改
  // 'pre':'https://prexjcec.xjcec.com/',//pre环境
  'test': 'https://testyqt.xjcec.com', // 测试环境  勿改
  'pre':'https://yqt.xjcec.com',//pre环境
  'dev': 'https://testyqt.xjcec.com'  // 本地开发环境  
}
const envNameList = () => Object.keys(signLogin)
// 基础url
exports.signLogin =  signLogin[process.env.VUE_APP_EDITION || envNameList()[local-1]]
