<template>
  <div class="login_view">
    <div class="big_box">
      <div class="login_box" style="margin: 0 auto">
        <!-- <div class="left_inner"></div> -->

        <div
          class="login_inner display_flex"
          style="display: flex"
          v-show="isRegister"
        >
          <div class="img_on">
            <img src="../../assets/login_img/2.png" alt="" />
          </div>
          <div class="display_flex left61">
            <transition name="fade">
              <div class="login_from" v-if="isRegister == 1">
                <!-- 登录组件 -->
                <loginComp
                  @WXLogin="WXLogin"
                  @toLogin="toLogin"
                  v-if="loginStatus == 1"
                />

                <!-- 注册组件 -->
                <signup
                  @WXLogin="WXLogin"
                  @toLogin="toLogin"
                  v-if="loginStatus == 2"
                />

                <!-- 找回密码组件 -->
                <retrieve v-if="loginStatus == 3" @toLogin="toLogin" />
              </div>
            </transition>
            <!-- 微信登录绑定手机号 -->
            <div class="login_from" v-if="isRegister == 2">
              <bind :wxData="wxData" @bindToLogin="bindToLogin" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 服务理念 -->
    <!-- <div class="service">
      <div class="center service_box">
        <div class="sercice_item">
          <div class="img"></div>
          <div class="title flx_space">
            <div class="one">货真价值</div>
            <div class="two">
              <div>严格评审</div>
              <div>产品货真价值，杜绝虚假</div>
            </div>
          </div>
        </div>
        <div class="sercice_item">
          <div class="img2"></div>
          <div class="title flx_space">
            <div class="one">品质服务</div>
            <div class="two">
              <div>全心全意</div>
              <div>一对一的全程服务</div>
            </div>
          </div>
        </div>
        <div class="sercice_item">
          <div class="img3"></div>
          <div class="title flx_space">
            <div class="one">国企担当</div>
            <div class="two">
              <div>品质商家</div>
              <div>核心企业让买家无忧</div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="login_bnner">
      <div class="center shabiui">
        <!-- Swiper -->
        <div class="swiper-container" v-if="partnerList.length">
          <div class="swiper-wrapper" v-if="partnerList.length">
            <div :key="i" v-for="(item, i) in partnerList" class="swiper-slide">
              <div class="swiper_item">
                <img
                  :src="item.partner_img"
                  style="width: 100%; height: 100%"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-button-next swiper-button-black"></div>
        <div class="swiper-button-prev swiper-button-black"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { reqGetToekn } from "@/api/public";
import { getpartnerList } from "@/api/home";
import * as types from "@/store/action-types";
import Swiper from "swiper";

import "swiper/dist/css/swiper.css";
import "swiper/dist/js/swiper.js";
import loginComp from "../components/login/login";
import signup from "../components/login/signup";
import retrieve from "../components/login/retrieve";
import bind from "../components/login/bind";
const { baseUrl } = require("@/config/index.js");
export default {
  components: { loginComp, signup, retrieve, bind },
  data() {
    return {
      loginStatus: 1,
      marginTopNum: 0,
      isRegister: 0,
      wechatId: "",
      wechatName: "",
      userImg: "",
      wxData: {
        wechatId: "",
        wechatName: "",
        userImg: "",
      },
      partnerList: [],
    };
  },
  created() {
    if (process.browser) {
      let num = (document.body.offsetHeight - 80 - 60 - 616) / 2;
      this.marginTopNum = num > 10 ? num : 50;
    }
  },
  mounted() {
    this.gettokens();
    if (this.$route.query && this.$route.query.key) {
      if (this.$route.query.key == "bind") {
        this.isRegister = 2;
      } else {
        // window.localStorage.clear();
        this.isRegister = 1;
        this.loginStatus = [1, 2, 3].includes(Number(this.$route.query.key))
          ? Number(this.$route.query.key)
          : 1;
      }
    } else {
      this.isRegister = 1;
      this.loginStatus == 1;
    }

    let wx_data = window.localStorage.getItem("yt_WX");
    try {
      if (wx_data) {
        let wx_obj = JSON.parse(wx_data);
        this.wxData = wx_obj;
      } else {
        this.isRegister = 1;
        this.loginStatus == 1;
      }
    } catch (e) {
      this.isRegister = 1;
      this.loginStatus == 1;
    }
  },
  methods: {
    gettokens() {
      const userIds = Vue.ls.get(types.userId);
      // console.log(userId);
      if (!userIds) {
        let config = {
          grant_type: "init",
          client_id: "xjcec",
          client_secret: "xjcec_secret",
          scope: "all",
        };
        reqGetToekn(config).then((res) => {
          if (res.code == 0) {
            Vue.ls.set(types.ACCESS_TOKEN, res.data.accessToken);
            Vue.ls.set(types.REFRESH_TOKEN, res.data.refreshToken);
            Vue.ls.set(types.checkUkeyWhite, res.data.checkUkeyWhite);
            Vue.ls.set(types.EXPIRES_IN, res.data.expiresTime + "000");
            this.getList();
          }
        });
      } else {
        this.getList();
      }
    },
    getList() {
      const that_ = this;
      // 合作伙伴
      getpartnerList({
        dictType: "partner",
        pageNum: 1,
        pageSize: 12,
      }).then((partner) => {
        if (partner.code == 0) {
          this.partnerList = [];
          partner.data.list.map((item) => {
            if (that_.isJSON(item.dictValue)) {
              let obx = JSON.parse(item.dictValue);
              item.partner_img =
                baseUrl + "third/third/ossGetFile/" + obx.partner_img;
              item.jump_url = obx.jump_url;
              this.partnerList.push(item);
            }
          });
        }
        this.$nextTick(() => {
          new Swiper(".swiper-container", {
            speed: 2000,
            // loop: true,
            slidesPerView: 5,
            spaceBetween: 14,
            autoplay: {
              delay: 2000,
            },
            observer: true, //修改swiper自己或子元素时，自动初始化swiper
            observeParents: true, //修改swiper的父元素时，自动初始化swiper
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
          });
        });
      });
    },
    isJSON(str) {
      if (typeof str == "string") {
        try {
          JSON.parse(str);
          return true;
        } catch (e) {
          return false;
        }
      }
    },
    webOpen() {
      window.open("https://beian.miit.gov.cn/");
    },
    getUserInfo() {
      // this.getUserData()
    },
    // ...mapActions({
    //   getUserData: `user/${types.GET_USERINFO}`
    // })
    /*
     * logo点击跳转首页
     */
    logoClick() {
      this.$router.push({
        path: "/",
      });
    },
    /*
     *  子组件回调方法  替换路由参数
     */
    toLogin(index) {
      this.loginStatus = index;
      this.$router.replace({
        path: "/login",
        query: {
          key: index,
        },
      });
    },
    /**
     * 绑定回调
     */
    bindToLogin(index) {
      this.isRegister = 1;
      this.loginStatus = index;
      this.$router.replace({
        path: "/login",
        query: {
          key: index,
        },
      });
    },
    /*
     * 微信登录
     */
    async WXLogin() {
      let path = `${window.location.protocol}//${window.location.host}/wxLogin`;
      console.log(path);
      // let res = await this.$axios.get('/portal/yt/unauth/member/wechat?url='+ path);
      // window.location.href = res;
    },
  },
};
</script>

<style lang="scss" scoped>
.img_on {
  margin-left: 51px;
  margin-top: 44px;
  img {
    width: 608px;
    height: 392px;
  }
}
.left61 {
  margin-left: 61px;
}
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  left: 38px;
  top: 74px;
  right: auto;
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  right: 38px;
  top: 74px;
  left: auto;
}
.swiper_item {
  width: 177px;
  height: 71px;
  // border: 1px solid #ccc;
}
.swiper-container {
  width: 1245px;
  // padding: 0 40px;
}
.shabiui {
  position: relative;
  width: 1400px;
  padding-top: 32px;
}
.ico_input {
  position: relative;
  .ico_box {
    position: absolute;
    top: 38px;
    left: 12px;
  }
}
.login_bnner {
  height: 152px;
  background-image: url(../../assets/img/bg_bottom.png) no-repeat;
}
.service {
  width: 100%;
  height: 212px;
  background-color: #f7f7f7;
  .service_box {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
  }
  .sercice_item {
    display: flex;
    width: calc(calc(100% / 3) - 20px);
    margin: 10px;
    align-items: center;
    justify-content: center;
    .img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background-image: url("../../assets/images/icon01.png");
    }
    .img2 {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background-image: url("../../assets/images/icon02.png");
    }
    .img3 {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background-image: url("../../assets/images/icon03.png");
    }
    .title {
      margin-left: 40px;
      height: 100px;
      padding-bottom: 6px;
      .one {
        font-size: 26px;
        font-weight: 400;
        color: #000;
      }
      .two {
        font-size: 16px;
        color: #000;
      }
    }
  }
}
.login_view {
  box-sizing: border-box;
  background-size: 100% 100%;
  .left_inner {
    width: 339px;
    height: 327px;
    margin-left: 185px;
    background: url("../../assets/images/loginbanner.png") no-repeat;
    background-size: 100% 100%;
  }
}
.big_box {
  min-width: 1903px;
  margin-top: 108px;
  /* background: #f7f9ff; */
  background: url("../../assets/login_img/3.png") no-repeat;
  background-size: 100% 100%;
  height: 707px;
}
.login_header {
  width: 100%;
  height: 80px;
  padding: 0 50px;
  box-shadow: 0px 1px 5.76px 0.24px rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
}

.kefu_text {
  width: 220px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  span {
    margin-left: 14px;
    color: #1c8eeb;
    font-size: 22px;
  }
}

.login_box {
  display: flex;
  justify-content: center;
  /* justify-content: space-between; */
  align-items: center;
  // margin-top: 100px;
  // height: 616px;
  // padding-top: 19px;
  .login_inner {
    margin-top: 52px;
    // margin-right: 100px;
    // margin-top: 250px;
    width: 1200px;
    height: 600px;
    // background-color: #fff;
    background: url("../../assets/login_img/1.png") no-repeat;
    background-size: 100% 100%;
    padding-top: 72px;
    // box-shadow: 0px 1px 5.76px 0.24px rgba(0, 0, 0, 0.06);
    // box-shadow: 0px 7px 27px 0px rgba(133, 169, 231, 0.51);
    border-radius: 20px;
    overflow: hidden;
    // border-radius: 4px;
    .login_from {
      width: 358px;
      margin: 0 auto;
      height: 559px;
    }
  }
}

.login_box:after {
  content: "";
  min-height: inherit;
  font-size: 0;
}

.login_bottom {
  height: 60px;
  padding-bottom: 39px;
  text-align: center;
  font-size: 14px;
  color: #666;
}
::v-deep .swiper-slide {
  width: 177px !important;
}
::v-deep .swiper-button-black {
  margin-top: -10px;
  height: 20px;
}
</style>
