<template>
  <div>
    <div class="login_all">
      <div class="welcome_login">登录您的账户</div>
      <div class="from_tab cursor_hover">
        <div>
          <span
            style="cursor: pointer"
            :class="[tabIndex == 1 ? 'active_weight' : '']"
            @click="handleClick(1)"
            >密码登录</span
          >
          <div
            style="height: 4px"
            :class="[tabIndex == 1 ? 'active_tab' : '']"
          ></div>
        </div>
        <div>
          <span
            style="cursor: pointer"
            :class="[tabIndex == 0 ? 'active_weight' : '']"
            @click="handleClick(0)"
            >验证码登录</span
          >
          <div
            style="height: 4px"
            :class="[tabIndex == 0 ? 'active_tab' : '']"
          ></div>
        </div>
      </div>
      <!-- 验证码登录 -->
      <div style="text-align: center" v-if="tabIndex == 0">
        <div class="ico_input">
          <div class="ico_box">
            <!-- <i class="hy-icon-yonghuming"></i> -->
            <img src="../../../assets/login_img/icon_people.png" alt="" />
          </div>
          <input
            class="input_text"
            :class="[errorType == 1 ? 'err_border' : '']"
            maxlength="11"
            @input="phoneNum = phoneNum.replace(/[^\d]/g, '')"
            type="text"
            placeholder="输入手机号码"
            v-model.trim="phoneNum"
            @focus="clearErrType"
            @keyup.enter="subimtInfo"
          />
        </div>
        <div class="login_code_box margin_top19">
          <div class="ico_input">
            <div class="ico_box">
              <!-- <i class="hy-icon-yanzhengma"></i> -->
              <img src="../../../assets/login_img/4.png" alt="" />
            </div>
            <input
              class="input_text input_num"
              :class="[errorType == 2 ? 'err_border' : '']"
              maxlength="6"
              type="text"
              placeholder="输入验证码"
              v-model.trim="code"
              @focus="clearErrType"
              @keyup.enter="subimtInfo"
            />
          </div>
          <div
            v-if="!msgCode"
            class="code_btn cursor_hover"
            @click="getMsgCode"
          >
            发送验证码
          </div>
          <div v-if="msgCode" class="code_btn code_send_active">
            已发送({{ timer }}s)
          </div>
        </div>
        <div class="subimt_btns" v-if="code == '' || phoneNum == ''">
          登&nbsp;&nbsp;&nbsp;录
        </div>
        <div class="subimt_btn" @click="subimtPhone" v-else>
          登&nbsp;&nbsp;&nbsp;录
        </div>
        <div class="signup_forget">
          <div class="signup_now cursor_hover" @click="toRetrieve">
            忘记密码？
          </div>
          <div class="signup_now cursor_hover" @click="tozhuce">立即注册</div>
        </div>
        <div class="third_party_login">
          <i></i>
          <span>其他方式登录</span>
          <i></i>
        </div>
        <div class="login-mode">
          <img
            @click="WXLogin"
            style="width: 35px; display: inline-block; cursor: pointer"
            src="../../../assets/images/wechart.png"
            alt=""
          />
          <!-- <img
            @click="UkeyLogin"
            style="width: 35px; display: inline-block; cursor: pointer"
            src="../../../assets/images/three.png"
            alt=""
          />
         -->
        </div>
      </div>
      <!-- 密码登录 -->
      <div style="text-align: center" v-if="tabIndex == 1">
        <div class="ico_input">
          <div class="ico_box">
            <!-- <i class="hy-icon-shouji"></i> -->
            <img src="../../../assets/login_img/icon_people.png" alt="" />
          </div>
          <input
            class="input_text"
            type="text"
            placeholder="输入手机号码"
            v-model.trim="userName"
            maxlength="11"
            :class="[errorType == 11 ? 'err_border' : '']"
            @focus="clearErrType"
            @keyup.enter="subimtInfo"
          />
        </div>
        <div class="login_code_box margin_top19">
          <div style="width: 100%" class="ico_input">
            <div class="ico_box">
              <!-- <i class="hy-icon-mima"></i> -->
              <img src="../../../assets/login_img/icon_password2.png" alt="" />
            </div>
            <input
              class="input_text"
              type="password"
              placeholder="输入密码"
              v-model.trim="password"
              maxlength="20"
              :class="[errorType == 12 ? 'err_border' : '']"
              @focus="clearErrType"
              @keyup.enter="subimtInfo"
            />
          </div>
        </div>
        <div
          class="subimt_btns"
          v-if="userName == '' || password == ''"
          style="cursor: pointer"
        >
          登&nbsp;&nbsp;&nbsp;录
        </div>
        <div
          class="subimt_btn cursor_hover"
          @click="subimtInfo"
          v-if="userName != '' && password != ''"
        >
          登&nbsp;&nbsp;&nbsp;录
        </div>
        <div class="signup_forget">
          <div
            class="signup_now cursor_hover"
            @click="toRetrieve"
            style="cursor: pointer"
          >
            忘记密码？
          </div>
          <div
            class="signup_now cursor_hover"
            @click="tozhuce"
            style="cursor: pointer"
          >
            立即注册
          </div>
        </div>
        <div class="third_party_login">
          <i></i>
          <span>其他方式登录</span>
          <i></i>
        </div>

        <div class="login-mode">
          <img
            @click="WXLogin"
            style="width: 35px; display: inline-block; cursor: pointer"
            src="../../../assets/images/wechart.png"
            alt=""
          />
          <!-- <img
            @click="UkeyLogin"
            style="width: 35px; display: inline-block; cursor: pointer"
            src="../../../assets/images/three.png"
            alt=""
          /> -->
        </div>
      </div>
      <signDownloadDrive ref="signDownloadDrive"></signDownloadDrive>
    </div>
  </div>
</template>

<script>
import signLogin from "@/config/signLoginConfig";
import $ from "jquery";
import { logIn, getsendSmsCodes } from "@/api/home.js";
import { reqGetToekn } from "@/api/public";
import * as ESASignClient from "@/utils/signConfig/ESASignClient.js";
import md5 from "js-md5";
import * as types from "@/store/action-types";
import Vue from "vue";
import signDownloadDrive from "@/components/signDownloadDrive.vue";
export default {
  components: { signDownloadDrive: signDownloadDrive },
  data() {
    return {
      msgCode: false,
      timer: 60, //定义初始时间为60s
      tabIndex: 1,
      errorType: 0,
      phoneNum: "",
      code: "",
      userName: "",
      password: "",
      dialogVisible: true,
    };
  },
  mounted() {
    let geturl = window.location.href;
    let getqyinfo = geturl.split("?")[1];
    let getqys = new URLSearchParams(getqyinfo);
    let token = getqys.get("token");
    if (getqys.get("userId") != undefined && getqys.get("userId") != "null") {
      const loading = this.$loading({
        lock: true,
        text: "登录中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      logIn({
        grant_type: "refresh_token",
        client_id: "xjcec",
        client_secret: "xjcec_secret",
        scope: "all",
        refresh_token: token,
        clientType: "trade_front",
      }).then((res) => {
        loading.close();
        if (res.code && res.code != 0) {
          return this.$YTmsg(res.message || "登陆失败", "warning");
        }
        this.setLoaclStorage(res);
        this.$router.push({
          path: "/",
        });
      });
    }
  },
  created() {},
  methods: {
    toSignup() {
      this.$emit("toLogin", 2);
    },
    tozhuce() {
      this.$emit("toLogin", 2);
    },
    toRetrieve() {
      this.$emit("toLogin", 3);
    },
    //  微信登录
    WXLogin() {
      this.$emit("WXLogin");
    },
    //UkeyLogin
    UkeyLogin() {
      this.$refs.signDownloadDrive.changeState();
      ESASignClient.ESACheckIsKey(this.JsESACheckIsKey, null, false);
    },
    //ukey 登录开始
    JsESACheckIsKey(status, msg, retpar, params) {
      let _this = this;

      if (status == 0) {
        ESASignClient.ESAVerifyPin(_this.JsESAVerifyPin, null, false);
      } else {
        alert(msg);
      }
    },
    JsESAVerifyPin(status, msg, retpar, params) {
      let _this = this;
      console.log(status);
      if (status == 0) {
        _this.ShieldSendCer();
      } else {
        alert(msg);
      }
    },
    ShieldSendCer() {
      let _this = this;
      ESASignClient.PDFSignFreeCloud(null, "", false);
      var strBuiessId = "";
      // var strBuiessId = document.getElementById("BuiessId").value;
      //签名身份认证
      var hashType = 0; //0-根据ukey类型判断摘要类型；1-MD5 ;2-SHA1 ;3-SHA256 ;4-SM3
      ESASignClient.BasePkcs7Sign(
        strBuiessId,
        0,
        _this.JsBaseLogin,
        null,
        false
      );
    },
    //回调函数-身份认证
    JsBaseLogin(status, msg, retpar, params) {
      let _this = this;
      if (status == 0) {
        var strSignP7 = retpar[0];
        //alert(strSignP7);

        var appid = "d01fa78a4c3e4fad82703705b618b4c3"; //授权账号
        var strBuiessId = "";
        // var strBuiessId = document.getElementById("BuiessId").value;

        var version = "1.0";
        var apptokentype = "1";
        var timestamp = "20211014135851"; //时间戳

        var fuwuURL = signLogin.signLogin + "/uess/v1/gateway.do"; //后台服务地址
        var strToken = "ebd91896ce68436cb7a6bbd061824886"; //token

        _this.JsVerifySignEx(
          fuwuURL,
          version,
          apptokentype,
          strToken,
          appid,
          timestamp,
          strSignP7,
          strBuiessId
        );
      } else {
        alert(msg);
      }
    },
    //js函数获取数据
    JsVerifySignEx(
      urlstr,
      version,
      apptokentype,
      apptoken,
      appid,
      timestamp,
      strp7data,
      strBuiessId
    ) {
      let _this = this;
      var bzipdata = { p7bData: strp7data, signSrc: strBuiessId };
      var strdata = JSON.stringify(bzipdata);

      var inputdata1 =
        "method=client.ukey.cert" +
        "&app_token_type=" +
        apptokentype +
        "&charset=UTF-8" +
        "&app_token=" +
        apptoken +
        "&version=" +
        version +
        "&app_id=" +
        appid +
        "&timestamp=" +
        timestamp +
        "&biz_content=" +
        strdata;

      //成功向后台请求ajax
      var ajax = $.ajax({
        type: "post",
        async: false,
        cache: false,
        url: urlstr,
        dataType: "text",
        data: inputdata1,
        success: function (json) {
          let data = JSON.parse(json).response;
          if (data.code == 0) {
            let paramObj = {
              grant_type: "ukey",
              client_id: "xjcec",
              client_secret: "xjcec_secret",
              scope: "all",
              socialCode: data.idNumber,
            };
            reqGetToekn(paramObj)
              .then((res) => {
                console.log(res);
                if (res.code == 0) {
                  _this.setLoaclStorage(res);
                  _this.$router.push({
                    path: "/",
                  });
                } else {
                  return _this.$YTmsg(res.message || "登陆失败", "warning");
                }
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            alert(json);
          }
        },
      });
    },
    //ukey 登录结束
    clearErrType() {
      this.errorType = 0;
    },
    // tab切换
    handleClick(index) {
      this.tabIndex = index;
      this.errorType = 0;
    },
    // // 手机号校验
    // isPhoneAvailable(e, index) {
    //   if(/^\s*$/.test(e.target.value)) return;
    //   let res = this.$verify.phone(e.target.value);

    //   res
    //     ? (this.errorType = 0)
    //     : ((this.errorType = index),
    //       this.$YTmsg( "请输入正确的手机号", "error"));
    // },
    // // 验证码校验
    // isCodeAvailable(e, index){
    //   if(/^\s*$/.test(e.target.value)) return;
    //   let res = this.$verify.code(e.target.value);

    //   res
    //     ? (this.errorType = 0)
    //     : ((this.errorType = index),
    //       this.$YTmsg( "验证码只能是6位数字", 'error'));
    // },
    //  手机号验证码登录
    async subimtPhone() {
      if (!this.$verify.phone(this.phoneNum)) {
        this.errorType = 1;
        this.$YTmsg("请填写正确的手机号", "error");
        return;
      }
      if (!this.$verify.code(this.code)) {
        this.errorType = 2;
        this.$YTmsg("请填写正确的验证码", "error");
        return;
      }
      this.errorType = 0;

      if (this.errorType) return;
      let res = await logIn({
        grant_type: "sms",
        client_id: "xjcec",
        client_secret: "xjcec_secret",
        scope: "all",
        mobile: this.phoneNum,
        code: this.code,
      });

      if (res.code && res.code != 0) {
        return this.$YTmsg(res.message || "登陆失败", "warning");
      }

      this.setLoaclStorage(res);
      this.$router.push({
        path: "/",
      });
    },
    //  用户校验
    // isUserNameAvailable(e, index){
    //   let res = this.$verify.userName(e.target.value);

    //   res
    //     ? (this.errorType = 0)
    //     : ((this.errorType = index),
    //       this.$YTmsg("输入用户名/手机号，支持汉字，字母与数字，4-14位", 'error'));
    // },
    // 密码校验
    // isPasswordAvailable(e, index){
    //   let res = this.$verify.password(e.target.value);

    //   res
    //     ? (this.errorType = 0)
    //     : ((this.errorType = index),
    //       this.$YTmsg("密码为6-20位，数字/字母组合", 'error'));
    // },
    //  登录
    async subimtInfo() {
      if (!this.$verify.userName(this.userName)) {
        this.errorType = 11;
        this.$YTmsg("请填写正确的用户名/手机号", "error");
        return;
      }

      // if(!this.$verify.password(this.password)) return this.$YTmsg('密码为6-20位，数字/字母组合', "error");
      // this.errorType = 0;

      if (this.errorType) return;

      let res = await logIn({
        grant_type: "captcha",
        client_id: "xjcec",
        client_secret: "xjcec_secret",
        scope: "all",
        username: this.userName,
        password: md5(this.password),
      });

      if (res.code && res.code != 0) {
        return this.$YTmsg(res.message || "登陆失败", "warning");
      }
      this.setLoaclStorage(res);
      this.$router.push({
        path: "/",
      });
    },
    //    发送登录短信验证码
    async getMsgCode() {
      if (!this.$verify.phone(this.phoneNum))
        return this.$YTmsg("请填写正确的手机号", "error");

      let res = await getsendSmsCodes({ phone: this.phoneNum });

      if (res.code && res.code != 0) {
        return this.$YTmsg(res.message || "验证码发送失败", "error");
      }
      this.$YTmsg("验证码已发送,请注意查收", "success");

      if (this.msgCode) return;
      this.msgCode = true;

      let auth_timer = setInterval(() => {
        this.timer--; //递减时间
        if (this.timer <= 0) {
          this.msgCode = false;
          this.timer = 60;
          clearInterval(auth_timer);
        }
      }, 1000);
    },
    // localStorage存储用户信息
    setLoaclStorage(res) {
      console.log(res);
      localStorage.setItem("keyP", "1");
      Vue.ls.set(types.ACCESS_TOKEN, res.data.accessToken);
      Vue.ls.set(types.REFRESH_TOKEN, res.data.refreshToken);
      Vue.ls.set(types.checkUkeyWhite, res.data.checkUkeyWhite);
      Vue.ls.set(types.EXPIRES_IN, res.data.expiresTime + "000");
      Vue.ls.set(types.userId, res.data.userId);
      Vue.ls.set(types.enterpriseId, res.data.enterpriseId);
      Vue.ls.set(types.IF_OPEN_FWDT, res.data.ifOpenFwdt);
      window.localStorage.setItem("token", res.data.accessToken);

      window.localStorage.setItem("phone", res.data.telephone || "");
      window.localStorage.setItem("userId", res.data.userId);
      window.localStorage.setItem("buttenpremissions", res.data.permissions);
    },
  },
};
</script>

<style lang="scss" scoped>
.cursor_hover {
  // cursor:pointer;
}
.login_all {
  width: 400px;
}
.login-mode {
  width: 143px;
  // display: flex;
  // justify-content: space-between;
  margin: 15px auto 0;
}
.ico_input {
  position: relative;
  .ico_box {
    position: absolute;
    top: 14px;
    left: 14px;
    img {
      width: 22px;
      height: 24px;
    }
  }
}
.welcome_login {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 400;
  color: #333333;
  font-weight: 600;
  margin-bottom: 22px;
}
.active_weight {
  font-weight: 600;
}
.code_top {
  top: 15px !important;
}
.from_tab {
  width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  margin-bottom: 26px;
  color: #666;
  span {
    display: block;
    // width: 175px;
    font-size: 18px;
    display: block;
    // width: 175px;
    font-size: 21px;
    padding: 8px 0;
    // border-bottom: #e8e8e8 2px solid;
  }
  .active_tab {
    margin: 0 auto;
    width: 27px;
    height: 4px;
    background: #00a7fe;
    border-radius: 2px;
  }
}
.signup_forget {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.input_text {
  width: 100%;
  height: 54px;
  border-radius: 4px;
  box-shadow: -7px 3px 15px 3px rgba(5, 33, 26, 0.06);
  // border: #dddddd 1px solid;
  padding-left: 56px;
  // margin-left: 38px;
  outline: none;
  font-size: 16px;
}
.input_text:focus {
  outline: 0;
  border: 1px solid #2d6dd2;
}
.input_num {
  width: 200px;
  height: 54px !important;
}

.margin_top19 {
  margin-top: 20px;
}
.margin_top40 {
  margin-top: 40px;
}
.login_code_box {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.code_btn {
  width: 126px;
  line-height: 54px;
  text-align: center;
  color: #fff;
  box-sizing: border-box;
  font-size: 16px;
  cursor: pointer;
  background: linear-gradient(90deg, #2079fb, #4cb2fc);
  border-radius: 2px;
}

.subimt_btn {
  width: 100%;
  line-height: 52px;
  background: linear-gradient(90deg, #2079fb, #4cb2fc);
  color: #fff;
  font-size: 18px;
  text-align: center;
  border-radius: 2px;
  margin-top: 42px;
  cursor: pointer;
}
.subimt_btns {
  cursor: pointer;
  width: 100%;
  line-height: 52px;
  background: #ccc;
  color: #fff;
  font-size: 18px;
  text-align: center;
  border-radius: 2px;
  margin-top: 42px;
}

.signup_now {
  cursor: pointer;
  text-align: center;
  margin-top: 12px;
  color: #1c8eeb;
  font-size: 14px;
}
.line {
  height: 12px;
  width: 1px;
  background-color: #dddddd;
  margin: 22px 20px 0;
}
.forget_word {
  text-align: center;
  margin-top: 22px;
  color: #666666;
  font-size: 14px;
}

.third_party_login {
  // margin: 41px 0 14px 0;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #999;
  font-size: 14px;
  i {
    width: 110px;
    height: 1px;
    background-color: #ddd;
    margin-top: 1px;
  }
}
::v-deep .swiper-button-black {
  color: #ccc !important;
}
</style>
